body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* PaymentForm.css */
.payment-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-element {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}

.pay-button {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #1976d2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #318eeb;
}

.pay-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}


/* Add this CSS to your global stylesheet or in a CSS module */
.spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
      -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
      -webkit-transform: rotate(360deg);
  }
}


.options-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 10%;
  font-size: 18px;
  
}

.exam {
  margin: 20px;
}

.question-section {
  margin-bottom: 20px;
}

.question-text {
  font-size: 23px;
}

.question-count {
  font-size: 18px;
  margin-bottom: 20px;
  color: blue;
}

.options-label {
  margin-bottom: 5px;
  
}

input[type="radio"] { 
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-bottom: 20px;

}

.exam-result-header {
  color: #096eec;
  font-size: 40px;
}

.score-section {
  font-size: 29px;
  padding-top: 50px;
  padding-bottom: 50px;
}